.footer_container{
    display: flex;
    justify-content: space-around; 
   flex-direction: row;
   padding-left: 40px;
   padding-right: 40px;
}
.dez-page-text{
    font-size: 14px;
}
@media (max-width:600px) {
    .footer_container{
         padding-left: 30px;
         padding-right: 0px;
         display: flex;
         justify-content: center;
         flex-direction: column;
        
    }
    .hide_mobile{
        display: none;
    }
}
@media (min-width:600px) {
    
    .hide_web{
        display: none;
    }
}

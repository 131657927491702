
.book_card_items_home {
  height: 210px;
  width: 230px;
  padding: 20px 20px;
  cursor: pointer;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border: 2px solid #2293fa;
  border-radius: 10px;
  margin: 10px;
}
.book_card_items_home:hover {
  -webkit-transform: scale(1.07);
  -webkit-transition: all 0.5s;
  transform: scale(1.07);
  transition: all 0.5s;
  border: 2px solid #2293fa;
  box-shadow: 0 0 16px rgba(17, 17, 26, 0.1);
}

.from_box {
  display: flex;
  gap: 20px;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
}
.card_date {
  font-size: 12px;
}

.book_Card_price {
  color: #007597;
  font-size: 20px;
}
.book_Card_order {
  color: #007597;
  font-size: 16px;
}
.book_now_heading {
  margin: auto;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  font-family: bitter;
}

.from_line {
  width: 70px;
  height: 1px;
  background-color: gray;
}
.background_card {
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

@media (width< 900px) {
  .book_now_heading {
    margin: auto;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin: 30px 0px;
  }
}
@media screen and (width<600px) {

  .book_card_items_home {
    height: 200px;
    width: 230px;
    padding: 20px 20px;
    cursor: pointer;
    gap: 5px;
    display: flex;
    background-color: rgba(255, 255, 255, 0.6);
    border: 2px solid #2293fa;
    border-radius: 10px;
    margin: 10px;
  }
  .background_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (width<450px) {
  .background_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

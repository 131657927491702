.mainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid lightgray;
  }
  .cheap_field_container {
    padding: 20px;
    width: 450px;
    margin: 0px 10px;
  }
  .fields_Container {
    width: 93%;
  }
.styl_tab_container{
    display: flex;
}
.tab_container{
    display: flex;
    position: relative;
    z-index: 100;
}
.tab_form_styl {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  font-family: "Bitter";
  color: white;
  background-color: rgba(0, 149, 250, 0);
}
.select_tab_form_styl {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  font-family: "Bitter";
  color: black;
  background-color: white;
}
.tab_form {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  font-family: "Bitter";
  color: white;
  background-color: #2293fa;
}
.select_tab_form {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  font-family: "Bitter";
  color: gray;
  background-color: white;
}

.wrapper_home {
  margin-bottom: 30px;
  margin-top: 10px;
}
.hotel_wrapper {
  margin-top: 40px;
}
.trip_form_header_text {
  display: flex;
  justify-content: end;
  flex-direction: row;
  color: white;
  font-weight: 700;
  font-size: 35px;
  background-color: white;
  padding-top: 20px;
}
.cheap_trip_form_header_text {
  display: flex;
  justify-content: end;
  flex-direction: row;
  color: white;
  font-weight: 700;
  font-size: 35px;
  background-color: white;
}
.inputContainer {
  border: 1px solid #0095fa;
  width: 100%;
  border-radius: 3px;
  font-size: 0.7rem;
  /* font-family: work sans; */
  margin-top: 5px;
  margin-bottom: 5px;
  height: 40px;
  padding-left: 3px;
  color: gray;
  background-color: #ffffff;
}
.cheap_input_container {
  border: 1px solid #0095fa;
  width: 100%;
  border-radius: 3px;
  font-size: 0.7rem;
  height: 35px;
  padding-left: 3px;
  color: gray;
  background-color: #ffffff;
}

#text::placeholder {
  color: gray;
  opacity: 1;
}
#bookNow::placeholder {
  color: gray;
  opacity: 1;
}
.form-error {
  color: red;
}

#text::placeholder {
  color: gray;
  opacity: 1;
}
#bookNow::placeholder {
  color: gray;
  opacity: 1;
}
.form-error {
  color: red;
}
.mainPassengers {
  margin-top: 20px;
  background-color: white;
  width: 250px;
  border: 0.2rem solid #468dc7;
  z-index: 1;
}

.book_now_mainPassenger {
  /* margin-top: 20px; */
  background-color: white;
  width: 250px;
  border: 0.2rem solid #468dc7;
  z-index: 1;
}

.divider {
  background-color: gray;
  width: 100%;
  height: 0.1px;
}
.adultContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.Adultbutton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}
.counterStyle {
  margin-right: 10px;
  background-color: #468dc7;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: white;
  border-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.countText {
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: gray;
  margin-top: 16px;
}
.AdultIncrease {
  margin-left: 10px;
  background-color: #468dc7;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: white;
  border-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.categoryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
}
.Childbutton {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
}
.count1Text {
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: gray;
  margin-top: 28px;
}
.passengerCategory {
  padding: 17px;
  color: #468dc7;
  font-size: 14px;
}
.Infantbutton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}
.count2Text {
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: gray;
  margin-top: 18px;
}
.doneContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.doneButtonStyle {
  cursor: pointer;
  background-color: #468dc7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  padding-top: 10px;
  color: white;
  margin-top: 10px;
  margin-bottom: 20px;
  text-transform: none;
  border-width: 0;
  font-size: 15px;
  width: 90%;
}
.doneButtonStyle2 {
  cursor: pointer;
  background-color: #468dc7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  padding-top: 10px;
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  text-transform: none;
  border-width: 0;
  font-size: 15px;
  width: 30%;
}
.form_bookNow_wrapper {
  background-color: white;
  padding: 5px;
  height: 550px;
}
.find_now_button {
  cursor: pointer;
  background-color: #5caed7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  color: white;
  text-transform: none;
  border-width: 0;
  font-size: 16px;
  width: 95%;
  height: 41px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 14px;
  font-family: lora;
}

.date_field {
  width: 400px;
  height: 390px;
  border-top: 0.1rem solid rgb(208, 208, 208);
  border-left: 0.1rem solid rgb(208, 208, 208);
  border-right: 0.1rem solid rgb(208, 208, 208);
  border-bottom: 0.1rem solid rgb(208, 208, 208);
  font-family: lora;
  padding-bottom: 10px;
}
.date_field2 {
  border-top: 0.1rem solid rgb(208, 208, 208);
  border-left: 0.1rem solid rgb(208, 208, 208);
  border-right: 0.1rem solid rgb(208, 208, 208);
  border-bottom: 0.1rem solid rgb(208, 208, 208);
  height: 270px;
  font-family: lora;
}
.done_range {
  border-left: 0.1rem solid rgb(208, 208, 208);
  border-right: 0.1rem solid rgb(208, 208, 208);
  border-bottom: 0.1rem solid rgb(208, 208, 208);
  background: white;
  margin-top: 0px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  font-family: lora;
}

@media screen and (width<600px) {
  .auto_complete_form {
    position: absolute;
    max-width: 300px;
    height: 300px;
    background-color: white;
    box-shadow: 1px 2px 9px #468dc7;
    z-index: 1;
    font-family: lora;
  }
  .main_text {
    /* padding-top: 5px; */
    color: white;
    font-size: 1.2rem;
    font-weight: 100;
    width: 100%;
  }
  .cheap_field_container {
    height: 650px;
  }
}

@media screen and (width<350px) {
  .auto_complete_form {
    position: absolute;
    max-width: 250px;
    height: 300px;
    background-color: white;
    box-shadow: 1px 2px 9px #468dc7;
    z-index: 1;
    font-family: lora;
  }
  .main_text {
    /* padding-top: 5px; */
    color: white;
    font-size: 1.2rem;
    font-weight: 100;
    width: 100%;
  }
}
@media screen and (width<901px) {
  .date_field {
    width: 400px;
    margin-left: -180px;
  }
  .main_text {
    /* padding-top: 5px; */
    color: white;
    font-size: 1.2rem;
    font-weight: 100;
    width: 100%;
  }
}
@media screen and (width<600px) {
  .date_field {
    width: 360px;
    margin-left: 0px;
  }
  .departure_text {
    padding-top: 5px;
    color: white;
    font-size: 0.7rem;
    margin-bottom: 15px;
  }

  .mainPassengers {
    margin-top: 20px;
    background-color: white;
    width: 250px;
    border: 0.2rem solid #468dc7;
    z-index: 1;
    margin-left: -80px;
  }
  .inputContainer {
    border: 0.1rem solid #c9c9c9;
    width: 100%;
    font-size: 0.7rem;
    /* font-family: work sans; */
    height: 35px;
    color: white;
  }
  .main_text {
    /* padding-top: 5px; */
    color: white;
    font-size: .8rem !important;
    font-weight: 100;
    width: 100%;
    margin: 0 !important;
    /* height: 30px; */
  }
  .book_now_mainPassenger {
    background-color: white;
    width: 90%;
    border: 0.2rem solid #468dc7;
    z-index: 1;
  }
}

@media screen and (width<320px) {
  .date_field {
    width: 330px;
    /* margin-left: -25px; */
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

.dropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  /* width: 300px;
  height: 300px; */
}
.auto_complete_form {
  position: absolute;
  margin-top: 30px;
  background-color: white;
  width: 300px;
  max-height: 300px;
  box-shadow: 1px 2px 9px #468dc7;
  z-index: 1;
  overflow-y: scroll;
  font-family: lora;
}
.dropdown:empty {
  border: none;
}

.dropdown-row {
  color: black;
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
  font-size: 16px;
  padding: 5px;
}
.dropdown-row:hover {
  background-color: lightgray;
}
input {
  width: 220px;
}

.search-inner {
  display: flex;
}
.error {
  color: red;
}
.styling_error {
  color: red;
}
.main_text {
  color: #777777;
  font-size: 1rem;
  font-weight: 500;
}
.cheap_main_text {
  color: #777777;
  font-size: 0.8rem;
  font-weight: 500;
}
.main {
  /* padding-top: 5px; */
  color: black;
  font-size: 0.8rem;
  padding: 5px;
}
.departure_text {
  padding-top: 5px;
  color: white;
  font-size: 0.8rem;
}
.nav_bar_rig_title {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}
.nav_bar_phone {
  font-size: 20px;
  color: #007597;
  font-weight: 750;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}
.header_text {
  text-transform: none;
}

.nav_bar_rig_title2 {
  font-size: 12px;
  padding: 5px;
  display: flex;
  flex-direction: row-reverse;
}
.main_header_container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-menu li {
  display: flex;
  align-items: flex-start;
}
.flight_page_text {
  font-size: 13px;
}
.working_header {
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: space-between;
  margin: 0px 170px 0px 120px;
}
.top_header_child_text {
  font-size: 18px;
}
.top_header_container {
  height: 30px;
}

.arrow_down {
  align-items: center;
  justify-content: center;
  display: flex;
}
@media screen and (width<500px) {
  .working_header {
    margin: 0;

    display: none;
    align-items: center;
    margin: 10px;
    justify-content: space-between;
  }
  .top_header_child_text {
    font-size: 18px;
  }
  .top_header_container {
    height: 0px;
  }
  .mobile_header {
    position: absolute;
    background-color: white;
    z-index: 110;
    top: 0;
    width: 100%;
    height: 100vh;
  }
}
@media screen and (width>550px) {
  .web_hidden {
    display: none;
  }
}

.react-slide-title {
  font-size: 40px;
}

.swiper_slider_two .inner {
  height: 300px;
}

@media (max-width: 800px) {
  .react-slide-title {
    font-size: 20px;
  }
  .swiper_slider_two .inner {
    height: 100px;
  }
  .tab_form {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
    font-family: "Bitter";
  }
  .select_tab_form {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
    font-family: "Bitter";
    color: gray;
    background-color: white;
  }
  .tab_container {
    display: flex;
    position: relative;
    z-index: 100;
    justify-content: center;
    margin-top: 10px;
  }
  .trip_form_header_text{
    padding-top: 0 ;
  }
  .MuiInputBase-input{
font-size: .9rem !important;
  }
}


.sticky_number_bar {
  width: 100%;
  background-color: rgb(51, 150, 206);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  padding: 10px 0;
  cursor: pointer;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sticky_number_bar h4, .sticky_number_bar h2{
  color:#fff;
  margin:0;
}
.sticky_number_bar i{
  font-size:30px;
  color:#fff;
}
.sticky_number_bar h4{
  font-size:18px;
}
.sticky_number_bar h2{
  font-size:25px;
}

.instant_flight_modal_wrapper {
  background-color: white;
  width: 400px;
  height: 280px;
  position: fixed;
  left: 0px;
  margin-right: 20px;
  bottom: 0px;
  cursor: pointer;
  padding-top: 10px;
  z-index: 1;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  z-index: 100;
}
.instant_flight_button {
  background-color: rgba(0, 122, 193, 0.8);
  border-radius: 5px;
  position: fixed;
  left: 0;
  bottom: 00;
  width: 230px;
  height: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
@media screen and (width<400px) {
  .instant_flight_modal_wrapper {
    background-color: white;
    width: 100%;
    height: 280px;
    position: fixed;
    left: 0px;
    margin-right: 20px;
    bottom: 0px;
    cursor: pointer;
    padding-top: 10px;
    border-top: 1px solid lightgray;

    z-index: 100;
  }
  .instant_flight_button {
    background-color: rgba(0, 122, 193, 0.8);
    border-radius: 5px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}
